import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Analytics } from '@vercel/analytics/react';

import LoadingScreen from './components/LoadingScreen';
import HelpPrompt from './components/HelpPrompt';
import InterfaceUI from './components/InterfaceUI';
import eventBus from './EventBus';
import './style.css';

const App = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        eventBus.on('loadingScreenDone', () => {
            setLoading(false);
        });
    }, []);

    return (
        <div id="ui-app">
            {!loading && <HelpPrompt />}
            <LoadingScreen />
            <Analytics />
        </div>
    );
};

const createUI = () => {
    const element = document.getElementById('ui');
    if (element) {
        createRoot(element).render(<App />);
    }
};

const createVolumeUI = () => {
    const element = document.getElementById('ui-interactive');
    if (element) {
        createRoot(element).render(<InterfaceUI />);
    }
};

export { createUI, createVolumeUI };
